import { useEffect } from "react";

import { onActiveBusinessFiltersChangeState } from "@keepeek/commons";
import { useSetRecoilState } from "recoil";

import { useActiveFilters } from "./useActiveFilters";

export const useInitOnActiveBusinessFiltersChange = () => {
  const { setActiveFilters } = useActiveFilters();
  const setOnActiveBusinessFiltersChange = useSetRecoilState(onActiveBusinessFiltersChangeState);
  useEffect(() => {
    // Set the atom that contains a function to update activeFilters
    setOnActiveBusinessFiltersChange(() => setActiveFilters);
  }, [setActiveFilters, setOnActiveBusinessFiltersChange]);
};
