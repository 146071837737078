import { useEffect } from "react";

import { KpkApiHitSearchType } from "@keepeek/api-client";
import { BusinessFilter, BusinessFilterExtraParams, useReset } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilState, useRecoilValue } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { basketDetailState } from "../atoms/basketDetail";
import {
  activeBusinessFiltersFromURLSelector,
  activeRawURLFiltersFromURLSelector,
} from "../selectors/activeFilters";
import { useLBF } from "./useLBF";

export type UseActiveFilters = {
  activeFilters: BusinessFilter[];
  rawURLFilters: { lbf: string };
  setActiveFilters: (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => void;
  isFolderSearch: boolean;
};
/**
 * Update the filters with active filter connector (currently: LBF)
 * Receive the activeFilters at the same time for convenience
 * @author JBO
 */
export const useActiveFilters = (): UseActiveFilters => {
  const router = useRouter();
  const { setLBF } = useLBF();
  const resetDataview = useReset();
  const activeFilters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const rawURLFilters = useRecoilValue(activeRawURLFiltersFromURLSelector);
  const [basketDetail, setBasketDetailState] = useRecoilState(basketDetailState);

  useEffect(() => {
    if (
      router.pathname !== PagesPath.BASKET_ELEMENT_PAGE &&
      router.pathname !== PagesPath.ELEMENT_PAGE &&
      basketDetail &&
      basketDetail.length !== 0
    ) {
      setBasketDetailState([]);
    }
  }, [basketDetail, router, setBasketDetailState]);

  /**
   * Update active filters trough current active filters connector
   * At the moment, we're using LBF
   * @author JBO
   *
   * @param {BusinessFilter[]} filters
   */
  const setActiveFilters = (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => {
    setLBF(filters, extraParams);
    resetDataview();
  };

  return {
    activeFilters,
    rawURLFilters,
    setActiveFilters,
    isFolderSearch:
      activeFilters &&
      activeFilters.length === 1 &&
      activeFilters[0].filter &&
      activeFilters[0].filter.type === KpkApiHitSearchType.Folder.toString(),
  };
};
