import { KpkApiForm, KpkApiSheetField } from "@keepeek/api-client";
import {
  getMetamodelType,
  KpkMedia,
  MediaField,
  MediaLinksField,
  MetaField,
} from "@keepeek/commons";
import { getI18n } from "react-i18next";

import { Field, Metamodel, MetamodelTypeObject } from "../models/configuration/definitions/field";
import { FieldType, VisualAlertsSchema } from "../models/configuration/pages/search";
import { KAdminFields } from "../models/configuration/types/kAdminFields";
import { KAdminMediaFields } from "../models/configuration/types/kAdminMediaFields";

/**
 * Mandatory MediaLinksField.
 */
export const mandatoryLinksField: MediaLinksField[] = [
  MediaLinksField.FORM,
  MediaLinksField.PREVIEW,
  MediaLinksField.WHR,
  MediaLinksField.XLARGE,
];

/**
 * Mandatory metafields.
 * Used for example when we retrieve an element to obtain at least these metafields
 */
export const mandatoryMetaField: MetaField[] = [
  MetaField.TITLE,
  MetaField.ORIGINAL_FILE_NAME,
  MetaField.DURATION_IN_SECONDS,
  MetaField.MEDIA_TYPE,
];

/**
 * Internal technical fields.
 * Technical field necessary for the proper functioning of the application
 */
export const internalTechnicalFields: MediaField[] = [
  MediaField.ID,
  MediaField.HEIGHT,
  MediaField.WIDTH,
];

/**
 * Return field to display depending on media.
 *
 * @param fieldConfiguration full field configuration
 * @param media media
 * @forms all api forms
 */
export function getFieldToDisplay(
  fieldConfiguration: Field,
  media: KpkMedia,
  forms: KpkApiForm[] | null,
): string | undefined {
  if (!fieldConfiguration || !media) {
    return "";
  }

  if (fieldConfiguration.metamodel) {
    const metamodelConfiguration: Metamodel | undefined = fieldConfiguration.metamodel.find(
      (metamodelConfiguration) => metamodelConfiguration.metamodelId.includes(media.formId),
    );

    if (metamodelConfiguration) {
      return metamodelConfiguration.field;
    }
  }

  if (fieldConfiguration.metamodelType) {
    const metamodelType = getMetamodelType(media, forms);
    if (metamodelType) {
      const metamodelTypeConfiguration: MetamodelTypeObject | undefined =
        fieldConfiguration.metamodelType.find((metamodelTypeConfiguration) =>
          metamodelTypeConfiguration.metamodelType.includes(metamodelType),
        );

      if (metamodelTypeConfiguration) {
        return metamodelTypeConfiguration.field;
      }
    }
  }

  return fieldConfiguration.default;
}

/**
 * Return flat fields list to request API with all possible fields needed by visual alerts calcul
 *
 * @param visualAlerts visual alerts configuration
 */
export function getVisualAlertsFieldsForApi(visualAlerts: VisualAlertsSchema[]): string[] {
  const fieldsForApi: string[] = [];
  // retrieve sub status if at least one visual alert is based on it
  const isSubStatusNeeded = visualAlerts.some(
    (visualAlert) => visualAlert.fieldType === FieldType.SubStatus,
  );
  if (isSubStatusNeeded) {
    fieldsForApi.push(MediaLinksField.SUB_STATUS);
  }
  // get fields needed by visual alerts conditions for non sub status
  const fields = visualAlerts
    .filter((visualAlert) => visualAlert.fieldInternalName)
    .map((visualAlert) => visualAlert.fieldInternalName.trim());
  fieldsForApi.push(...fields);
  return fieldsForApi;
}

export function convertToFieldList(
  sheetFields: KpkApiSheetField[] | undefined,
  metaFields: KAdminMediaFields[],
): KAdminFields[] {
  const res: KAdminFields[] = [];
  sheetFields?.map((sheetField) => {
    res.push({
      id: sheetField.id,
      name: sheetField.title,
      type: getI18n().t("metaField"),
    });
  });
  metaFields.map((metaField) => {
    res.push({
      id: metaField.id,
      name: metaField.name,
      type: getI18n().t("mediaField"),
    });
  });
  return res;
}
